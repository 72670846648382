import { MarketMakerRequestStatusEnum } from '@forgd/supabase'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

interface INavItem { label: string, to: string, icon: string, iconColor?: 'text-primary-300' | 'text-green-600' | 'text-neutral-700' }

/**
 * is designed to handle a specific RFQ on the RFQ details page.
 * It fetches RFQ details and prepares the navigation sidebar
 */
export const useLiquidityRFQDetails = defineStore('rfq-details', () => {
  const route = useRoute()

  const backButtonUrl = ref<string>('/')

  const rfqDetailsQuery = computed(() => {
    return {
      id: rfqId.value as string,
    }
  })

  const rfqId = computed(() => {
    return route.params.rfqId
  })

  const { data: details, status, clear, refresh, execute } = useCoreData('/liquidity/rfq-details', {
    query: rfqDetailsQuery,
    immediate: false,
  })

  const refetchRFQDetails = async () => {
    clear()
    await refresh()
  }

  const navItems = computed<INavItem[]>(() => {
    const rfqId = route.params.rfqId
    const lockedIcon = 'i-heroicons-lock-closed'
    const unlockedIcon = 'i-heroicons-lock-open'
    const checkedIcon = 'i-heroicons-check-circle'

    const myQuotesRound1: INavItem = { label: 'Round 1: My Quotes', to: `/${rfqId}/round-1-my-quotes`, icon: lockedIcon, iconColor: 'text-neutral-700' }
    const outcomeRound1: INavItem = { label: 'Round 1: Outcome', to: `/${rfqId}/round-1-outcome`, icon: lockedIcon, iconColor: 'text-neutral-700' }
    const myQuotesRound2: INavItem = { label: 'Round 2: My Quotes', to: `/${rfqId}/round-2-my-quotes`, icon: lockedIcon, iconColor: 'text-neutral-700' }
    const outcomeRound2: INavItem = { label: 'Round 2: Outcome', to: `/${rfqId}/round-2-outcome`, icon: lockedIcon, iconColor: 'text-neutral-700' }

    switch (details.value?.rfq?.status) {
      case MarketMakerRequestStatusEnum.RoundOneWaitingForQuotes:
        myQuotesRound1.icon = unlockedIcon
        myQuotesRound1.iconColor = 'text-primary-300'
        break
      case MarketMakerRequestStatusEnum.RoundOneQuotesInReview:
        myQuotesRound1.iconColor = 'text-green-600'
        break
      case MarketMakerRequestStatusEnum.RoundOneQuotesAccepted:
        myQuotesRound1.iconColor = 'text-green-600'
        outcomeRound1.icon = checkedIcon
        outcomeRound1.iconColor = 'text-green-600'
        break
      case MarketMakerRequestStatusEnum.RoundTwoWaitingForQuotes:
        myQuotesRound1.iconColor = 'text-green-600'
        outcomeRound1.icon = checkedIcon
        outcomeRound1.iconColor = 'text-green-600'
        myQuotesRound2.icon = unlockedIcon
        myQuotesRound2.iconColor = 'text-primary-300'
        break
      case MarketMakerRequestStatusEnum.RoundTwoQuotesInReview:
        myQuotesRound1.iconColor = 'text-green-600'
        outcomeRound1.icon = checkedIcon
        outcomeRound1.iconColor = 'text-green-600'
        myQuotesRound2.iconColor = 'text-green-600'
        break
      case MarketMakerRequestStatusEnum.RoundTwoQuotesAccepted:
        myQuotesRound1.iconColor = 'text-green-600'
        outcomeRound1.icon = checkedIcon
        outcomeRound1.iconColor = 'text-green-600'
        myQuotesRound2.iconColor = 'text-green-600'
        outcomeRound2.icon = checkedIcon
        outcomeRound2.iconColor = 'text-green-600'
        break
    }
    return [myQuotesRound1, outcomeRound1, myQuotesRound2, outcomeRound2]
  })

  const summaryNavItem = computed<INavItem>(() => {
    return { label: 'Request Summary', to: `/${route.params.rfqId}/summary`, icon: 'i-heroicons-document-text', iconColor: 'text-neutral-700' }
  })

  const reset = () => {
    backButtonUrl.value = '/'
    details.value = null
    status.value = 'idle'
  }

  watch(() => route.params.rfqId, async (val, oldVal) => {
    if (!val || val !== oldVal) {
      reset()
    }
    if (val && val !== oldVal) {
      backButtonUrl.value = ['/active-rfqs', '/closed-rfqs'].includes(window.history.state.back) ? window.history.state.back : '/'
    }
  }, { immediate: true, deep: true })

  onMounted(() => {
    execute()
  })

  return {
    summaryNavItem,
    navItems,
    details,
    status,
    backButtonUrl,
    refetchRFQDetails,
    reset,
  }
})
